import AwardTypeController from "@/data/controllers/AwardTypeController"
import AwardTypeMaskController from "@/data/controllers/AwardTypeMaskController"
import BallotController from "@/data/controllers/BallotController"
import CategoryController from "@/data/controllers/CategoryController"
import ConferenceController from "@/data/controllers/ConferenceController"
import ConferenceAdminController from "@/data/controllers/ConferenceAdminController"
import ConferenceSettingController from "@/data/controllers/ConferenceSettingController"
import IdentityController from "@/data/controllers/IdentityController"
import NominationController from "@/data/controllers/NominationController"
import ResultController from "@/data/controllers/ResultController"
import StatController from "@/data/controllers/StatController"
import StatGroupController from "@/data/controllers/StatGroupController"
import StatMaskController from "@/data/controllers/StatMaskController"
import UserController from "@/data/controllers/UserController"
import VoteController from "@/data/controllers/VoteController"

export default {
  AwardTypeController,
  AwardTypeMaskController,
  BallotController,
  CategoryController,
  ConferenceController,
  ConferenceAdminController,
  ConferenceSettingController,
  IdentityController,
  NominationController,
  ResultController,
  StatController,
  StatGroupController,
  StatMaskController,
  UserController,
  VoteController,
}