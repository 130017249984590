/* eslint-disable */
import axios from 'axios'
import Cognito from './Cognito'

//THIS FILE IS NOT NECESSARY FOR COGNITO AUTH SETUP. ONLY RETURNS USER INFO FROM COGNITO ON AUTHORIZATION FOR DEV PURPOSES. CAN BE DELETED EVENTAULLY.

export default {
  getUserInfo() {
    const jwt = Cognito.auth.getSignInUserSession().getAccessToken().jwtToken    
    const USERINFO_URL = 'https://' + Cognito.auth.getAppWebDomain() + '/oauth2/userInfo'
    const requestData = {
      headers: {
          'Authorization': 'Bearer ' + jwt
      }
    }
    return axios.get(USERINFO_URL, requestData).then(response => {
      return response.data
    })
  },

  getUserTokenAndRefreshToken() {
    const jwt = Cognito.auth.getSignInUserSession().getAccessToken().jwtToken    
    const USERINFO_URL = 'https://' + Cognito.auth.getAppWebDomain().split('.com')[0] + '.auth.us-east-2.amazoncognito.com' + '/oauth2/token'
    const requestData = {
      headers: {
          'Authorization': 'Bearer ' + jwt
      }
    }
    return axios.post(USERINFO_URL, requestData).then(response => {
      return response.data
    })
  }
}
