import { Request } from "@/data/Requests"

async function ListCategories() {
  return await Request(`/categories`, {
    method: "GET",
  })
}

export default {
  ListCategories,
}