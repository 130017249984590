import { Request } from "@/data/Requests"

async function AdminListAwardTypeMasks() {
  return await Request(`/admin/award-type-masks`, {
    method: "GET",  
  })
}

async function AdminCreateAwardTypeMask(awardTypeId, globalSportId) {
  return await Request(`/admin/award-type-mask/${awardTypeId}/${globalSportId}`, {
    method: "POST",
  })
}

async function AdminDeleteAwardTypeMask(awardTypeId, globalSportId) {
  return await Request(`/admin/award-type-mask/${awardTypeId}/${globalSportId}`, {
    method: "DELETE",
  })
}

export default {
  AdminListAwardTypeMasks,
  AdminCreateAwardTypeMask,
  AdminDeleteAwardTypeMask,
}