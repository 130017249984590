import Controllers from "@/data/controllers"
import { SetUserId } from "@/data/Requests"

let _admin = null
let _conferences = null
let _support = null
let _users = null

let _usersSelectedIndex = null
let _conferencesSelectedIndex = null

let _initialized = false

async function Init() {
  // Load in user || admin || support creds, depending on JWT email.

  const res = await Controllers.IdentityController.GetIdentity()

  if(res && !res.hasError) {
    const identity = res.data

    _admin = identity.conferenceAdmin
    _conferences = identity.conferences
    _support = identity.supportAccount
    _users = identity.users
    
    if(
        _conferences &&
        _conferences.length &&
        _users &&
        _users.length
      )
    {
      // Setup so that the conference select can operate with duplicate ids
      _users.forEach((u, index) => u.index = index)
      _conferences.forEach((c, index) => c.index = index)
      
      // Add a field to conferences to differentiate duplicates.
      let conferencesDict = {}
      _conferences.forEach(c => {
        if(conferencesDict[c.name] == undefined) conferencesDict[c.name] = []
        conferencesDict[c.name].push(c)
      })
      Object.entries(conferencesDict)
        .forEach(([name, conferences]) => {
          if(conferences.length > 1) {
            conferences.forEach((c, index) => c.formattedName = `${name} (${index+1})`)
          }
          else
            conferences[0].formattedName = name
        })


        _loadSelectedIndicies()
      SetUserId(_users[_usersSelectedIndex].id)
    }
  }
  else {
    // Error handling
  }

  _initialized = true
}

function _storeSelectedIndicies() {
  localStorage.setItem("_usersSelectedIndex", _usersSelectedIndex)
  localStorage.setItem("_conferencesSelectedIndex", _conferencesSelectedIndex)
}
function _loadSelectedIndicies() {
  const usersIndex = Number(localStorage.getItem("_usersSelectedIndex"))
  const conferencesIndex = Number(localStorage.getItem("_conferencesSelectedIndex"))

  _usersSelectedIndex = usersIndex < _users.length ? usersIndex : 0
  _conferencesSelectedIndex = conferencesIndex < _conferences.length ? conferencesIndex : 0
}

function GetAdmin() {
  return _admin
}
function SetAdmin(admin) {
  _admin = admin
}
function GetConferences() {
  return _conferences
}
function GetConference() {
  if(_conferencesSelectedIndex == null) return null
  return _conferences[_conferencesSelectedIndex]
}
function SetConference(conference) {
  _conferences = [conference]
  _conferencesSelectedIndex = 0
}
function SelectConference(conference) {
  const conferenceIndex = _conferences.map(c => c.id).indexOf(conference.id)
  SelectIndicies(conferenceIndex)
}
function GetSupport() {
  return _support
}
function SetSupport(support) {
  _support = support
}
function GetUsers() {
  return _users
}
function GetUser() {
  if(_usersSelectedIndex == null) return null
  return _users[_usersSelectedIndex]
}
function SetUser(user) {
  _users = [user]
  _usersSelectedIndex = 0

  _conferencesSelectedIndex = 0
  // This shouldn't be needed because we only use SetUser for impersonation.
  // SetUserId(_users[0].id)
}
function SelectUser(user) {
  const userIndex = _users.map(u => u.id).indexOf(user.id)
  SelectIndicies(userIndex)
}
function SelectIndicies(index) {
  _usersSelectedIndex = index
  _conferencesSelectedIndex = index

  SetUserId(_users[_usersSelectedIndex].id)

  _storeSelectedIndicies()
}
function Initialized() {
  return _initialized
}

export default {
  GetAdmin,
  SetAdmin,
  GetConferences,
  GetConference,
  SetConference,
  SelectConference,
  GetSupport,
  SetSupport,
  GetUsers,
  GetUser,
  SetUser,
  SelectUser,
  SelectIndicies,
  Initialized,
  Init,
}