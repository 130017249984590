import { Request } from "@/data/Requests"

async function AdminListUsers() {
  return await Request(`/admin/users`, {
    method: "GET",  
  })
}

async function SupportListAccounts() {
  return await Request(`/support/users`, {
    method: "GET",  
  })
}

async function AdminCreateUser(userDto) {
  return await Request(`/admin/user`, {
    method: "POST",
    data: userDto
  })
}

async function SupportCreateSupportAccount(userDto) {
  return await Request(`/support/user`, {
    method: "POST",
    data: userDto
  })
}

async function AdminUpdateUser(userDto) {
  return await Request(`/admin/user`, {
    method: "PUT",
    data: userDto
  })
}

async function SupportUpdateSupportAccount(userDto) {
  return await Request(`/support/user`, {
    method: "PUT",
    data: userDto
  })
}

async function AdminEnableUser(userId) {
  return await Request(`/admin/user/${userId}/enable`, {
    method: "PATCH",
  })
}

async function AdminDisableUser(userId) {
  return await Request(`/admin/user/${userId}/disable`, {
    method: "PATCH",
  })
}

async function SupportDisableSupportAccount(userId) {
  return await Request(`/support/user/${userId}/disable`, {
    method: "PATCH",
  })
}

async function SupportEnableSupportAccount(userId) {
  return await Request(`/support/user/${userId}/enable`, {
    method: "PATCH",
  })
}

async function AdminSetUserPassword(userId, password) {
  return await Request(`/admin/user/${userId}/password`, {
    method: "PATCH",
    data: {
      password
    }
  })
}

async function SupportSetSupportAccountPassword(userId, password) {
  return await Request(`/support/user/${userId}/password`, {
    method: "PATCH",
    data: {
      password
    }
  })
}

async function AdminSetConfUserPassword(userId, password) {
  return await Request(`/admin/confadmin/${userId}/password`, {
    method: "PATCH",
    data: {
      password
    }
  })
}

async function GetUserPreferences() {
  return await Request(`/user/preferences`, {
    method: "GET",
  })
}

async function UpdateUserPreferences(userPreferenceDto) {
  return await Request(`/user/preferences`, {
    method: "PUT",
    data: userPreferenceDto
  })
}

function toUserDtoSimpleAdmin(user) {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    school: user.school,
    categoryId: user.categoryId,
    conferenceId: user.conferenceId,
    recieveNominationPhaseNotifications: user.recieveNominationPhaseNotifications,
    recieveVotingPhaseNotifications: user.recieveVotingPhaseNotifications,
    disabled: user.disabled,
  }
}

export default {
  AdminListUsers,
  AdminCreateUser,
  AdminUpdateUser,
  AdminDisableUser,
  AdminEnableUser,
  AdminSetUserPassword,
  GetUserPreferences,
  UpdateUserPreferences,
  toUserDtoSimpleAdmin,
  AdminSetConfUserPassword,
  SupportListAccounts,
  SupportCreateSupportAccount,
  SupportSetSupportAccountPassword,
  SupportDisableSupportAccount,
  SupportEnableSupportAccount,
  SupportUpdateSupportAccount
}