<template>
  <!-- <v-app
    v-if="signedIn()
      && $route.name != 'CognitoEntryPoint'
      && $route.name != 'Logout'
    "
    id="app"
    style="background-color: #EEE;"
  >    -->
  <v-app
    v-if="$route.name != 'CognitoEntryPoint' && $route.name != 'Logout'"
    id="app"
    style="background-color: #EEE;"
  >   
  
  <app-navigation :navigation-items="navigationItems"/>
  
  <v-main>
    <transition mode="out-in">
      <router-view :key="$route.path"/>
    </transition>
  </v-main>


    <!-- Users Warning expiration time is coming -->
    <div class="text-center">

      <!-- <v-btn
        color="orange-darken-2"
        @click="snackbar = true"
      >
        Open Snackbar
      </v-btn> -->

      <v-snackbar
        v-model="snackbar"
        :timeout="100000000"
      >
        <div class="d-flex flex-row align-center">
          <div class="mr-4">Your User Session is about to expire, would you like to remain logged in?</div>
          <v-btn
            class="mr-2"
            color="blue"
            variant="text"
            @click="refreshUserToken"
          >
            Yes
          </v-btn>          
          <v-btn
            color="bg-grey"
            variant="text"
            @click="snackbar = false"
          >
            No
          </v-btn>          
        </div>
      </v-snackbar>
    </div>

    <!-- Snackbar default -->
    <v-snackbar
      v-model="$root.snackbar.enabled"
      :color="$root.snackbar.color"
      :timeout="$root.snackbar.timeout"
    >
      <div
        class="w-100 d-flex align-center justify-center"
      >
        {{ $root.snackbar.text }}
      </div>
    </v-snackbar>

  </v-app>
</template>

<script>
import AppNavigation from '@/components/app/AppNavigation'
import Cognito from "@/app/Cognito"
import Identity from "@/app/Identity"
import { mapState } from 'vuex';
import store from '@/store';

console.log('APP VERSION: 1.1.5');

export default {
  name: "App",
  
  components: {
    AppNavigation,
  },

  data: () => ({
    navExpanded: true,
    LoginWarningModal: false,
    snackbar: false,
  }),
  
  computed: {   
    ...mapState({aboutToLogOut: state => state.aboutToLogOut}),
     
    navigationItems() {
      if(this.$route.path.startsWith('/support')) {
        return [
          {
            title: "Conferences",
            to: "/support/conferences",
            icon: "mdi-account-group",
          },
          {
            title: "Award Types",
            to: "/support/award-types",
            icon: "mdi-trophy-award",
          },
          {
            title: "Stat Groups",
            to: "/support/stat-groups",
            icon: "mdi-chart-bar",
          },
          {
            title: "Account Management",
            to: "/support/users",
            icon: "mdi-account-edit",
          },
          {
            title: "Logout",
            to: "/logout",
            icon: "mdi-logout",
          },
        ]
      }
      else if(this.$route.path.startsWith('/admin')) {
        return [
          {
            title: "Awards",
            icon: "mdi-seal",
            subItems: [
            {
                title: "Manage",
                to: "/admin/award-management",
                icon: "mdi-folder",
              },
              {
                title: "Add an Award",
                to: "/admin/add-award",
                icon: "mdi-plus",
              },
              
            ]
          },
          {
            title: "Voter Maintenance",
            to: "/admin/voter-maintenance",
            icon: "mdi-account-multiple",
          },
          {
            title: "Edit Stat Groups",
            to: "/admin/stat-groups",
            icon: "mdi-chart-bar",
          },
          {
            title: "Edit Award Types",
            to: "/admin/award-types",
            icon: "mdi-trophy-award",
          },
          {
            title: "Settings",
            to: "/admin/settings",
            icon: "mdi-cog",
          },
          {
            title: "Logout",
            to: "/logout",
            icon: "mdi-logout",
          },
        ]
      }
      else if(this.$route.path.startsWith('/voting')) {
        return [
          {
            title: "Home",
            to: "/voting",
            icon: "mdi-ballot",
          },
          {
            title: "Preferences",
            to: "/voting/preferences",
            icon: "mdi-account-cog"
          },
          {
            title: "Logout",
            to: "/logout",
            icon: "mdi-logout",
          },
        ]
      }

      return []
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    aboutToLogOut(newValue, oldValue) {
      // console.log(`aboutToLogOut from ${oldValue} to ${newValue}`);
      this.snackbar = newValue;
    }
  },

  methods: {
    signedIn() {
      return Cognito.SignedIn()
    },

    async userIdentityAvailable() {
      return await Identity.GetUser() != null
    },

    async refreshUserToken() {
      this.snackbar = false
      const response = await Cognito.refreshUserSession()
      if(response && response.expires_in === 3600) {
        store.dispatch('aboutToLogOut', false);
        this.$root.showSnackbar("User's session refreshed successfully!", "success", 5000);
        Cognito.getTokenWarning();
      }
      else this.$root.showSnackbar("There was an error refreshing user's session successfully.", "error", 5000);
    }
  },

  created() {
    const clearStorageCheck = localStorage.getItem('clearStorage')
    if (!clearStorageCheck)  localStorage.clear();
    localStorage.setItem('clearStorage', true);
  }
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}

.v-enter-active, .v-leave-active{
  transition: all 125ms ease-in;
}
</style>

<style lang="scss">
// Remove forced uppercase
.v-btn {
  text-transform:none !important;
}

// Throw the `expanding-v-data-table` class on the <v-data-table>
// and the `expanding-v-data-table--footer` class on a div in the footer slot.
.expanding-v-data-table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.expanding-v-data-table--footer {
  flex-grow: 20;
}
</style>
