<template>
  <v-navigation-drawer 
    :mini-variant="!expanded"
    color="#212121"
    permanent
    touchless
    app
  >
    <div
      class="d-flex flex-column"
      style="height: 100%;"
    >
      <v-list v-if="conferenceImgSrc() != null && !$route.path.startsWith('/support')">
        <v-list-item class="d-flex justify-center">
          <div
            v-if="expanded"
            style="max-width: 50%;"
          >
            <v-img :src="conferenceImgSrc()"/>
          </div>
          <v-list-item-avatar
            v-else
            class="mx-0"
          >
            <v-img :src="conferenceImgSrc()"/>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item v-if="expanded && $route.path.startsWith('/admin')">
          <v-list-item-title class="white--text d-flex flex-column align-center">
              <div class="text-h7 mt-2">
                Welcome,
              </div>
              <div class="text-h6">
                {{ adminFullName() }}
              </div>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else-if="expanded && $route.path.startsWith('/voting')">
          <v-list-item-title class="white--text d-flex flex-column align-center">
              <div class="text-h7 mt-2">
                Welcome,
              </div>
              <div class="text-h6">
                {{ userFullName() }}
              </div>
          </v-list-item-title>
        </v-list-item>

      </v-list>

      <!-- Fallback and Main Logo for /support. -->
      <v-list v-else>
        <v-list-item class="d-flex justify-center">
          <div
            v-if="expanded"
            style="max-width: 50%;"
          >
            <v-img src="https://s3.amazonaws.com/logos.sidearmsports.com/footer_logos/sidearm/sidearm-white.svg"/>
          </div>
          <v-list-item-avatar
            v-else
            class="mx-0"
          >
          <v-img src="https://s3.amazonaws.com/logos.sidearmsports.com/footer_logos/sidearm/sidearm-white.svg"/>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>

      <v-divider/>

      <v-list v-if="$route.path.startsWith('/voting') && expanded && conferences.length > 1">
        <v-list-item style="color: white">
          <v-list-item-title>
            <v-select
              v-model="filterConferenceIndex"
              :items="conferences"
              item-text="formattedName"
              item-value="index"
              color="black"
              solo
              dense
              hide-details
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider v-if="$route.path.startsWith('/voting') && conferences.length"/>

      <v-list dense>
        <div
          v-for="(navItem, index) in navigationItems"
          :key="index"
        >
          <v-list-item
            v-if="!navItem.subItems"
            :to="navItem.to"
            style="color: white;"
            link
          >
            <v-list-item-icon>
              <v-icon color="white">{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ navItem.title }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else
            class="white-list-group zero-margin-list-group-icon"
            :value="true"
            :prepend-icon="navItem.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="color: white;">{{ navItem.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(subItem, subIndex) in navItem.subItems"
              :key="subIndex"
              :to="subItem.to"
              style="color: white;"
              link
            >
              <v-list-item-title>{{ subItem.title}}</v-list-item-title>

              <v-list-item-icon>
                <v-icon color="white">{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <v-switch
        v-model="expanded"
        :label="expanded ? 'toggle sidebar' : ''"
        style="margin-top: auto; align-self: center"
        class="pa-2"
        hide-details
        dense
        dark
        flat
      >
      </v-switch>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Identity from "@/app/Identity"

export default {
  name: "AppNavigation",

  props: {
    navigationItems: {
      type: Array,
      required: true,
    }
  },

  data: () => ({
    expanded: true,
  }),

  computed: {
    conferences() {
      return Identity.GetConferences()
    },
    filterConferenceIndex: {
      get() {
        return Identity.GetConference().index
      },
      set(newVal) {
        Identity.SelectIndicies(newVal)
        this.$router.replace({ path: "/voting/clear-state" })
      }
    },
  },

  methods: {
    conferenceImgSrc() {
      const conference = Identity.GetConference()
      return conference != null
        ? `${conference.serviceUrl}/images/logos/site/site.png`
        : null
    },

    adminFullName() {
      const admin = Identity.GetAdmin()
      return `${admin.firstName} ${admin.lastName}`
    },

    userFullName() {
      const user = Identity.GetUser()
      return `${user.firstName} ${user.lastName}`
    }
  },

  created() {
    //
  }

}
</script>

<style lang="scss">
.white-list-group * {
  color: white !important;
}

.zero-margin-list-group-icon .v-list-item__icon {
  margin-left: 0px !important;
}
</style>