import { Request } from "@/data/Requests"

async function SupportListStatGroups() {
  return await Request(`/support/stat-groups`, {
    method: "GET",
  })
}

async function SupportGetStatGroup(statGroupId) {
  return await Request(`/support/stat-group/${statGroupId}`, {
    method: "GET",
  })
}

async function SupportCreateStatGroup(statGroup) {
  return await Request(`/support/stat-group`, {
    method: "POST",
    data: statGroup,
  })
}

async function SupportEditStatGroup(statGroup) {
  return await Request(`/support/stat-group`, {
    method: "PUT",
    data: statGroup,
  })
}

async function AdminListStatGroups(globalSportId) {
  return await Request(`/admin/stat-groups/${globalSportId}`, {
    method: "GET",
  })
}

export default {
  SupportListStatGroups,
  SupportGetStatGroup,
  SupportCreateStatGroup,
  SupportEditStatGroup,
  AdminListStatGroups,
}