import Cognito from "@/app/Cognito"
import Config from "@/app/Config"

const URL_BASE = Config.apiUrlBase

let _impersonationAdminId = null
// let _impersonationAdminId = 1000 // Bob Smith
let _impersonationConferenceId = null
// let _impersonationConferenceId = 1002 // ACC
let _impersonationUserId = null
// let _impersonationUserId = 1004 // Jane Doe
let _userId = null

const LOGGING_ENABLED = false

function logRequest(endpoint, requestOptions, response) {
  const consoleMethod = response != null && response.hasError ? console.error : console.log

  consoleMethod("**************************************************************")
  consoleMethod(`* Method: ${requestOptions.method}`)
  consoleMethod(`* Endpoint: ${endpoint}`)
  consoleMethod("* Data:", requestOptions.data != null ? JSON.parse(JSON.stringify(requestOptions.data)) : requestOptions.data)
  consoleMethod("* Response:", JSON.parse(JSON.stringify(response)))
  consoleMethod("**************************************************************")
}

function processRequestOptions(requestOptions) {
  const fetchOptions = {
    method: requestOptions.method,
    headers: {
      Authorization: `Bearer ${Cognito.GetJwt()}`,
    },
  }

  if(_impersonationAdminId != null)
    fetchOptions.headers["impersonation-admin-id"] = _impersonationAdminId
  if(_impersonationConferenceId != null)
    fetchOptions.headers["impersonation-conference-id"] = _impersonationConferenceId
  if(_impersonationUserId != null)
    fetchOptions.headers["impersonation-user-id"] = _impersonationUserId
  if(_userId != null)
    fetchOptions.headers["user-id"] = _userId

  if(requestOptions.data != null) {
    fetchOptions.headers["Content-Type"] = "application/json"
    fetchOptions.body = JSON.stringify(requestOptions.data)
  }

  return fetchOptions
}

export const Request = async (endpoint, requestOptions) => {
  const fetchOptions = processRequestOptions(requestOptions)

  let hasError = false
  let status
  let response
  try {
    response = await
      fetch(`${URL_BASE}${endpoint}`, fetchOptions)
        .then(res => {
          status = res.status
          if(!res.ok) {
            hasError = true
            return res.text()
          }
          else {
            const contentType = res.headers.get('content-type');
            if(contentType && contentType.includes("application/json")) return res.json()
            else return res.text()
          }
        })
  }
  catch(e) {
    console.error(e)
    hasError = true
  }
  
  const data = hasError ? null : response
  const error = hasError ? `${response}` : null

  const formattedResponse = {
    data,
    hasError,
    error,
    status,
  }

  if(LOGGING_ENABLED || formattedResponse.hasError) logRequest(endpoint, requestOptions, formattedResponse)

  return formattedResponse
}

export const SetImpersonationAdminId = (adminId) => {
  _impersonationAdminId = adminId
}

export const SetImpersonationConferenceId = (conferenceId) => {
  _impersonationConferenceId = conferenceId
}

export const SetImpersonationUserId = (userId) => {
  _impersonationUserId = userId
}

export const SetUserId = (userId) => {
  _userId = userId
}