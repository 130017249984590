/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Cognito from "@/app/Cognito"
import Identity from '@/app/Identity'
import { jwtDecode } from "jwt-decode";
import moment from 'moment'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  // START Cognito Routes
  {
    path: "/login",
    name: "CognitoEntryPoint",
  },
  { 
    path: "/logout",
    name: "Logout",
  },
  // END Cognito Routes

  // START Support Routes
  {
    path: "/support/conferences",
    name: "ConferenceManagement",
    component: () => import(/* webpackChunkName: "ConferenceManagement" */ '../views/support/ConferenceManagement.vue'),
  },
  {
    path: "/support/users",
    name: "UsersManagement",
    component: () => import(/* webpackChunkName: "ConferenceManagement" */ '../views/support/UserManagement.vue'),
  },
  {
    path: "/support/award-types",
    name: "AwardTypeManagement",
    component: () => import(/* webpackChunkName: "AwardTypeManagement" */ '../views/support/AwardTypeManagement.vue'),
  },
  {
    path: "/support/stat-groups",
    name: "StatGroupManagement",
    component: () => import(/* webpackChunkName: "StatGroupManagement" */ '../views/support/StatGroupManagement.vue'),
  },
  {
    path: "/support/stat-group/:statGroupId/stats",
    name: "StatManagement",
    props: route => ({
      statGroupId: Number(route.params.statGroupId),
    }),
    component: () => import(/* webpackChunkName: "StatManagement" */ '../views/support/StatManagement.vue'),
  },
  // END Support Routes

  // START Admin Routes
  {
    path: '/admin/add-award',
    name: 'Add Award',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AwardForm" */ '../views/admin/AwardForm.vue'),
  },
  {
    path: '/admin/edit-award/:ballotId',
    name: 'Edit Award',
    props: route => ({
      ballotId: Number(route.params.ballotId),
    }),
    component: () => import(/* webpackChunkName: "AwardForm" */ '../views/admin/AwardForm.vue'),
  },
  {
    path: '/admin/award-management',
    name: 'AwardManagement',
    component: () => import(/* webpackChunkName: "AwardManagement" */ '../views/admin/AwardManagement.vue'),
  },
  {  
    path: '/admin/nomination-results/:ballotId',
    name: 'NominationResults',
    props: route => ({
      ballotId: Number(route.params.ballotId),
    }),
    component: () => import(/* webpackChunkName: "NominationResults" */ '../views/admin/NominationResults.vue'),
  },
  {  
    path: '/admin/view-ballot/:ballotId',
    name: 'ViewBallot',
    props: route => ({
      ballotId: Number(route.params.ballotId),
    }),
    component: () => import(/* webpackChunkName: "ViewBallot" */ '../views/admin/ViewBallot.vue'),
  },
  {  
    path: '/admin/voting-results/:ballotId',
    name: 'VotingResults',
    props: route => ({
      ballotId: Number(route.params.ballotId),
    }),
    component: () => import(/* webpackChunkName: "VotingResults" */ '../views/admin/VotingResults.vue'),
  },
  {  
    path: '/admin/voter-maintenance',
    name: 'VoterMaintenance',
    component: () => import(/* webpackChunkName: "VoterMaintenance" */ '../views/admin/VoterMaintenance.vue'),
  },
  {
    path: '/admin/stat-groups',
    name: 'StatGroups',
    component: () => import(/* webpackChunkName: "StatGroups" */ '../views/admin/StatGroups.vue'),
  },
  {
    path: '/admin/award-types',
    name: 'AwardTypes',
    component: () => import(/* webpackChunkName: "AwardTypes" */ '../views/admin/AwardTypes.vue'),
  },
  {
    path: '/admin/settings',
    name: 'AdminSettings',
    component: () => import(/* webpackChunkName: "AdminSettings" */ '../views/admin/AdminSettings.vue'),
  },
  // END Admin Routes

  // START User Routes
  {  
    path: '/voting/preferences',
    name: 'VoterPreferences',
    component: () => import(/* webpackChunkName: "VoterPreferences" */ '../views/user/VoterPreferences.vue'),
  },
  {
    path: '/voting/clear-state',
    name: 'ClearState',
    component: () => import(/* webpackChunkName: "VoterPreferences" */ '../views/user/ClearState.vue'),
  },
  {  
    path: '/voting',
    name: 'Voting',
    component: () => import(/* webpackChunkName: "Voting" */ '../views/user/Voting.vue'),
  },
  {  
    path: '/voting/select-award/:ballotId',
    name: 'SelectAward',
    props: route => ({
      ballotId: Number(route.params.ballotId),
    }),
    component: () => import(/* webpackChunkName: "SelectAward" */ '../views/user/SelectAward.vue'),
  },
  {  
    path: '/voting/nominate/:ballotId/:awardTypeId',
    name: 'Add Nomination',
    props: route => ({
      ballotId: Number(route.params.ballotId),
      awardTypeId: Number(route.params.awardTypeId),
    }),
    component: () => import(/* webpackChunkName: "NominationForm" */ '../views/user/NominationForm.vue'),
  },
  {  
    path: '/voting/edit-nomination/:ballotId/:awardTypeId/:nominationId',
    name: 'Edit Nomination',
    props: route => ({
      ballotId: Number(route.params.ballotId),
      awardTypeId: Number(route.params.awardTypeId),
      nominationId: Number(route.params.nominationId),
    }),
    component: () => import(/* webpackChunkName: "NominationForm" */ '../views/user/NominationForm.vue'),
  },
  {  
    path: '/voting/vote/:ballotId',
    name: 'Vote',
    props: route => ({
      ballotId: Number(route.params.ballotId),
    }),
    component: () => import(/* webpackChunkName: "Vote" */ '../views/user/Vote.vue'),
  },
  // END User Routes

]

const router = new VueRouter({
  routes,
  mode: "history", // remove # from URL
})

// All Cognito policy should be applied here.
router.beforeEach(async (to, from, next) => {

  if(Cognito.SignedIn()) {
    if(to.name == "Logout") {
      // (results in a redirect, so next() is not needed.)
      Cognito.SignOut()
    }
    else {
      // Warning User about to refresh token
      Cognito.getTokenWarning();

      // Standard case.
      // Gate routes based on Identity.
      if(!Identity.Initialized()) await Identity.Init()

      if (Identity.GetSupport()?.disabled) {
        window.location.replace("/logout", '')
      }
      
      let route404 =
        to.matched.length == 0 // True 404
        || to.name == "CognitoEntryPoint"
        || Identity.GetSupport() == null && to.path.startsWith('/support')
        || Identity.GetAdmin() == null && to.path.startsWith('/admin')
        || Identity.GetUser() == null && to.path.startsWith('/voting')

      if(route404) {
        // Route based on role.
        if(Identity.GetSupport() != null) window.location.replace("/support/conferences", '')
        else if(Identity.GetAdmin() != null) window.location.replace("/admin/award-management", '')
        else if(Identity.GetUser() != null) window.location.replace("/voting", '')
      }
      else {
        next()
      }
    }
  }
  else { // !signedIn    
    if(to.name == "CognitoEntryPoint") {
      // User was redirected from the hosted Cognito UI to us.
      // Triggers onSuccess or onFailure in Cognito.js, which have their own router.push() effects.
      // (i.e. no need for next())
      Cognito.ConfirmSignIn()
    }
    else {
      // Save the user's intended target in local storage so that we can send
      // them there upon returning from the hosted Cognito UI.
      // (Redirect handled by Cognito.auth.userhandler.onSuccess)
      localStorage.setItem("targetUrl", to.fullPath)

      // Sign In by redirecting the user to the hosted Cognito UI.
      // (results in a redirect, so next() is not needed.)
      Cognito.SignIn()
    }
  }
})

export default router
