/* eslint-disable */
import { CognitoAccessToken, CognitoAuth, CognitoIdToken, StorageHelper } from 'amazon-cognito-auth-js'
import router from '../router/index'
import UserInfoApi from './UserInfoApi'
import Identity from "@/app/Identity"
import Config from "@/app/Config"
import { jwtDecode } from "jwt-decode";
import moment from 'moment'
import store from '@/store';

const CLIENT_ID = "579fe04mb2hakqgf3kpppgt9qi"
const APP_DOMAIN = "sso.id.sidearmdev.com"
const USERPOOL_ID = "SIDEARM-Platform-Users"
const REDIRECT_URI = Config.redirectUri
const REDIRECT_URI_SIGNOUT = Config.redirectUriSignout

const storageHelper = new StorageHelper()
const storage = storageHelper.getStorage()

const authData = {
  ClientId: CLIENT_ID,
  AppWebDomain: APP_DOMAIN,
  TokenScopesArray: ['openid', 'email'],
  RedirectUriSignIn: REDIRECT_URI,
  RedirectUriSignOut: REDIRECT_URI_SIGNOUT,
  UserPoolId: USERPOOL_ID,
  Storage: window.sessionStorage
}

const auth = new CognitoAuth(authData)
auth.responseType = 'code'


auth.userhandler = {
  onSuccess:  function (result) {
    // const refreshToken = result.getRefreshToken()


    UserInfoApi.getUserInfo()
      .then(response => {
        let targetUrl = localStorage.getItem("targetUrl")

        Identity.Init()
          .then(() => {
            if(Identity.GetSupport() != null) {
              // Always send support to Conference Management
              targetUrl = "/support/conference-management"
            }
            else if(Identity.GetAdmin() != null) {
              // Always send admins to Award Management
              targetUrl = "/admin/award-management"
            }
            else if(targetUrl == null || targetUrl == "/logout") {
              // Default voters to /voting
              targetUrl = "/voting"
            }

            // teste(response)
    
            window.location.replace(targetUrl)
          })
      })


    // UserInfoApi.getUserTokenAndRefreshToken()()
    //   .then(response => {
    //     let targetUrl = localStorage.getItem("targetUrl")


    //   })

  },
  onFailure: function (err) {
    router.replace({ path: '/error', query: { message: `Login Error: ${err}` } })
  }
}

function getUserInfoStorageKey() {
  const keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId()
  const tokenUserName = auth.signInUserSession.getAccessToken().getUsername()
  const userInfoKey = `${keyPrefix}.${tokenUserName}.userInfo`
  return userInfoKey
}

function SignIn() {
  auth.getSession()

  // Same thing as this (practically)
  // window.location.replace("https://sso.id.sidearmdev.com/login?redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Flogin%2Foauth2%2Fcode%2Fcognito&response_type=token&client_id=579fe04mb2hakqgf3kpppgt9qi&state=3ialHjBJwC7OK3yALHEKNAGydha4XbTh&scope=openid%20email")
}

function getRefreshToken() {
  const refreshTokenKey = Object.keys(auth.storage).filter(key => key.includes('refreshToken'));
  const refreshToken = auth.storage[refreshTokenKey];
  return refreshToken;
}

const refreshUserSession = async () => {
  const res = await fetch(`https://${auth.getAppWebDomain()}/oauth2/token`, {
    method: "POST",
    headers: new Headers({"content-type": "application/x-www-form-urlencoded"}),
    body: Object.entries({
      "grant_type": "refresh_token",
      // "grant_type": "authorization_code",
      "client_id": CLIENT_ID,
      // "code": 'token',
      // "code_verifier": codeVerifier,
      "redirect_uri": window.location.origin,
      "refresh_token": getRefreshToken(),
    }).map(([k, v]) => `${k}=${v}`).join("&"),
  });
  if (!res.ok) {
    throw new Error(await res.json());
  }
  const tokens = await res.json();
  auth.signInUserSession.setAccessToken(new CognitoAccessToken(tokens.access_token));
  auth.signInUserSession.setIdToken(new CognitoIdToken(tokens.id_token));

  return tokens;
}

function getTokenWarning(){
  const token = this.GetJWTAccessToken();
  const decoded = jwtDecode(token);
  const d = new Date(0);
  d.setUTCSeconds(decoded.exp);
  const minutesToWarnUser = 10;
  // console.log("minutes missing to warn User: ", minutesToWarnUser)
  const momentD = moment(d);
  let getMinutesLeft = () => {return momentD.diff(moment(Date.now()), 'minutes', true);}
  // console.log("Minutes left: ", getMinutesLeft())
  const minutesLeftInSec = getMinutesLeft() * 60 * 1000;
  const minutesToWarnInSec = minutesToWarnUser * 60 * 1000;

  setTimeout(() => getRefreshToken(), minutesLeftInSec - minutesToWarnInSec);
  const getRefreshToken = () => {
    store.dispatch('aboutToLogOut', true);
    
  }
}

function ConfirmSignIn() {
  // This function ends up calling getTokenQueryParameter which will parse
  // tokens and whatever else the Cognito UI sends back in query params.
  // After the parsing, the parsing results are returned as result to
  // auth.onSuccess.
  auth.parseCognitoWebResponse(window.location.href)
}

function SignOut() {
  if(auth.isUserSignedIn()) {
    const userInfoKey = getUserInfoStorageKey()
    auth.signOut()

    storage.removeItem(userInfoKey)
  }
}

function SignedIn() {
  return auth.isUserSignedIn()
}

function GetJwt() {
  return auth.getSignInUserSession().getIdToken().jwtToken
}

function GetJWTAccessToken() {
  return auth.getSignInUserSession().getAccessToken().jwtToken
}

export default {
  auth,
  SignIn,
  getTokenWarning,
  refreshUserSession,
  getRefreshToken,
  ConfirmSignIn,
  SignOut,
  SignedIn,
  GetJwt,
  getUserInfoStorageKey,
  GetJWTAccessToken,
}