import { Request } from "@/data/Requests"

async function AdminDeleteNomination(nominationId) {
  return await Request(`/admin/nomination/${nominationId}`, {
    method: "DELETE"
  })
}

async function CreateNomination(nominationDto) {
  return await Request(`/nomination`, {
    method: "POST",
    data: nominationDto,
  })
}

async function CreateCopyNomination(nominationDto) {
  return await Request(`/admin/nomination`, {
    method: "POST",
    data: nominationDto,
  })
}

async function UpdateNomination(nominationDto) {
  return await Request(`/nomination`, {
    method: "PUT",
    data: nominationDto,
  })
}

async function AdminUpdateNomination(nominationDto) {
  return await Request(`/admin/updatenomination`, {
    method: "PUT",
    data: nominationDto,
  })
}
async function DeleteNomination(nominationId) {
  return await Request(`/nomination/${nominationId}`, {
    method: "DELETE"
  })
}

export default {
  AdminDeleteNomination,
  CreateNomination,
  CreateCopyNomination,
  UpdateNomination,
  AdminUpdateNomination,
  DeleteNomination
}