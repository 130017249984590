import { Request } from "@/data/Requests"

async function AdminUpdateConferenceSettings(settings) {
  return await Request(`/admin/conference-settings`, {
    method: "PUT",
    data: settings,
  })
}

async function GetConferenceSettings() {
  return await Request(`/conference-settings`, {
    method: "GET",  
  })
}

export default {
  AdminUpdateConferenceSettings,
  GetConferenceSettings,
}