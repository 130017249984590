import { Request } from "@/data/Requests"

async function ListStatMasks() {
  return await Request(`/stat-masks`, {
    method: "GET",
  })
}

async function AdminCreateStatMask(statId) {
  return await Request(`/admin/stat-mask/${statId}`, {
    method: "POST"
  })
}

async function AdminDeleteStatMask(statId) {
  return await Request(`/admin/stat-mask/${statId}`, {
    method: "DELETE"
  })
}

export default {
  ListStatMasks,
  AdminCreateStatMask,
  AdminDeleteStatMask,
}