import { Request } from "@/data/Requests"

async function AdminGetBallot(ballotId) {
  return await Request(`/admin/ballot/${ballotId}`, {
    method: "GET",
  })
}

async function AdminListBallots() {
  return await Request(`/admin/ballots`, {
    method: "GET",  
  })
}

async function AdminCreateBallot(ballotDto) {
  return await Request(`/admin/ballot`, {
    method: "POST",
    data: ballotDto
  })
}

async function AdminUpdateBallot(ballotDto) {
  return await Request(`/admin/ballot`, {
    method: "PUT",
    data: ballotDto
  })
}

async function AdminBatchReset(ballotIds) {
  return await Request(`/admin/ballots/batch-reset`, {
    method: "PATCH",
    data: ballotIds,
  })
}

async function AdminPatchBallotPhase(ballotPhaseDto, ballotId) {
  return await Request(`/admin/ballot/${ballotId}/phase`, {
    method: "PATCH",
    data: ballotPhaseDto,
  })
}

async function ListBallots() {
  return await Request(`/ballots`, {
    method: "GET",
  })
}

async function GetBallot(ballotId) {
  return await Request(`/ballot/${ballotId}`, {
    method: "GET",
  })
}

function GetCurrentBallotPhase(ballot) {
  if(ballot.phaseManuallyOverriden) return ballot.overridePhase
  else {
    // TODO: Make sure this is eastern time.
    const now = new Date()

    if (now > new Date(ballot.nominationPhaseStartDate) && now < new Date(ballot.nominationPhaseEndDate))
        return 2 // BallotPhase.Nomination
    if (now > new Date(ballot.votingPhaseStartDate) && now < new Date(ballot.votingPhaseEndDate))
        return 3 // BallotPhase.Voting
    if (now > new Date(ballot.votingPhaseEndDate))
        return 4 // BallotPhase.Archived

    // Note: This means that the interim between Nominations and Voting is also
    // considered 'Preview'. Should ask about adding a intermission phase.
    return 1 // BallotPhase.Preview;
  }
}

function interpretDatesAsUtc(ballot) {
  // The dates are sent to the API as UTC, but sent back without the UTC identifier (the "Z").
  // Without the "Z", the dates are read as local time.
  if(ballot.gamesOccuringBetweenStartDate != null) ballot.gamesOccuringBetweenStartDate += "Z"
  if(ballot.gamesOccuringBetweenEndDate != null) ballot.gamesOccuringBetweenEndDate += "Z"
  if(ballot.nominationPhaseStartDate != null) ballot.nominationPhaseStartDate += "Z"
  if(ballot.nominationPhaseEndDate != null) ballot.nominationPhaseEndDate += "Z"
  if(ballot.votingPhaseStartDate != null) ballot.votingPhaseStartDate += "Z"
  if(ballot.votingPhaseEndDate != null) ballot.votingPhaseEndDate += "Z"
}

export default {
  AdminGetBallot,
  AdminListBallots,
  AdminCreateBallot,
  AdminUpdateBallot,
  AdminBatchReset,
  AdminPatchBallotPhase,
  GetBallot,
  ListBallots,
  GetCurrentBallotPhase,
  interpretDatesAsUtc,
}