import Vue from 'vue'
import Vuex from 'vuex'
import Controllers from "@/data/controllers"

Vue.use(Vuex)

// Vuex is used here for sharing API data that is highly invariate and used in many components.

export default new Vuex.Store({
  state: {
    aboutToLogOut: false,
    awardTypesByGlobalSportId: {},
    conferenceDivisions: null,
    conferenceSeasons: null,
    conferenceSports: null,
    ballotTypes: [ // Hard coded, but if this field is set to null it goes back to querying the API.
      {
          "name": "PreSeason",
          "value": 1
      },
      {
          "name": "PostSeason",
          "value": 2
      },
      {
          "name": "Weekly",
          "value": 3
      },
      {
        "name": "Poll",
        "value": 4
      }
    ],
    ballotPhases: [ // Hard coded, but if this field is set to null it goes back to querying the API. (These should never change)
      {
          "name": "Preview",
          "value": 1
      },
      {
          "name": "Nomination",
          "value": 2
      },
      {
          "name": "Nomination Review",
          "value": 5
      },
      {
        "name": "Voting",
        "value": 3
      },
      {
        "name": "Voting Review",
        "value": 6
      },
      {
        "name": "Archived",
        "value": 4
      },
  ],
  },
  getters: {
    awardTypesByGlobalSportId: (state) => async (globalSportId) => {
      if(state.awardTypesByGlobalSportId[globalSportId] == undefined) {
        const res = await Controllers.AwardTypeController.AdminListAwardTypes(globalSportId)
        if(res && !res.hasError) state.awardTypesByGlobalSportId[globalSportId] = res.data
      }

      return state.awardTypesByGlobalSportId[globalSportId]
    },

    conferenceDivisions: async (state) => {
      if(state.conferenceDivisions == null) {
        const res = await Controllers.ConferenceController.ListDivisions()
        if(res && !res.hasError) state.conferenceDivisions = res.data
      }

      return state.conferenceDivisions
    },
    conferenceSeasons: async (state) => {
      if(state.conferenceSeasons == null) {
        const res = await Controllers.ConferenceController.ListSeasons()
        if(res && !res.hasError) state.conferenceSeasons = res.data
      }

      return state.conferenceSeasons
    },

    conferenceSports: async (state) => {
      if(state.conferenceSports == null) {
        const res = await Controllers.ConferenceController.ListSports()
        if(res && !res.hasError) state.conferenceSports = res.data
      }

      return state.conferenceSports
    },

    ballotTypes: async (state) => {
      if(state.ballotTypes == null) {
        // const res = await AwardController.GetAwardInfo()
        // state.ballotTypes = res.data.periods
      }

      return state.ballotTypes
    },

    ballotPhases: async (state) => {
      if(state.ballotPhases == null) {
        // const res = await AwardController.GetAwardInfo()
        // state.ballotPhases = res.data.statuses
      }

      return state.ballotPhases
    },

  },
  actions: {
    clearConferenceDivisions({ commit }) {
      commit("clearConferenceDivisions")
    },
    clearConferenceSeasons({ commit }) {
      commit("clearConferenceSeasons")
    },
    clearConferenceSports({ commit }) {
      commit("clearConferenceSports")
    },
    aboutToLogOut({ commit }, payload) {
      commit("aboutToLogOut", payload)
    },
  },
  mutations: {
    clearConferenceDivisions(state) {
      state.conferenceDivisions = null
    },
    clearConferenceSeasons(state) {
      state.conferenceSeasons = null
    },
    clearConferenceSports(state) {
      state.conferenceSports = null
    },
    aboutToLogOut(state, payload) {
      state.aboutToLogOut = payload
    },
  },
  modules: {
    //
  }
})
