import { Request } from "@/data/Requests"

async function SupportListStats(statGroupId) {
  return await Request(`/support/stat-group/${statGroupId}/stats`, {
    method: "GET",
  })
}

async function SupportCreateStat(stat) {
  return await Request(`/support/stat`, {
    method: "POST",
    data: stat,
  })
}

async function SupportEditStat(stat) {
  return await Request(`/support/stat`, {
    method: "PUT",
    data: stat,
  })
}

async function SupportEditStats(stats) {
  return await Request(`/support/stats`, {
    method: "PUT",
    data: stats,
  })
}

async function SupportDeleteStat(statId) {
  return await Request(`/support/stat/${statId}`, {
    method: "DELETE",
  })
}

export default {
  SupportListStats,
  SupportCreateStat,
  SupportEditStat,
  SupportEditStats,
  SupportDeleteStat,
}