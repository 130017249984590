import { Request } from "@/data/Requests"

async function SupportCreateConferenceAdmin(admin) {
  return await Request(`/support/conference-admin`, {
    method: "POST",
    data: admin,
  })
}

async function SupportUpdateConferenceAdmin(admin) {
  return await Request(`/support/conference-admin`, {
    method: "PUT",
    data: admin,
  })
}

async function SupportEnableConferenceAdmin(adminId) {
  return await Request(`/support/conference-admin/${adminId}/enable`, {
    method: "PATCH",
  })
}

async function SupportDisableConferenceAdmin(adminId) {
  return await Request(`/support/conference-admin/${adminId}/disable`, {
    method: "PATCH",
  })
}

export default {
  SupportCreateConferenceAdmin,
  SupportUpdateConferenceAdmin,
  SupportEnableConferenceAdmin,
  SupportDisableConferenceAdmin,
}