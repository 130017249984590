import { Request } from "@/data/Requests"

async function AdminDeleteVote(voteId) {
  return await Request(`/admin/vote/${voteId}`, {
    method: "DELETE",
  })
}

async function AdminDeleteVotes(userId, ballotId) {
  return await Request(`/admin/votes/${userId}/${ballotId}`, {
    method: "DELETE",
  })
}

export default {
  AdminDeleteVote,
  AdminDeleteVotes,
}