import { Request } from "@/data/Requests"

async function CreateResults(results) {
  return await Request(`/results`, {
    method: "POST",
    data: results,
  })
}

export default {
  CreateResults,
}