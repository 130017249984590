import { Request } from "@/data/Requests"
import moment from "moment"

async function SupportCreateConference(conference) {
  return await Request(`/support/conference`, {
    method: "POST",
    data: conference,
  })
}

async function SupportUpdateConference(conference) {
  return await Request(`/support/conference`, {
    method: "PUT",
    data: conference,
  })
}

async function SupportListConferences() {
  return await Request(`/support/conferences`, {
    method: "GET",
  })
}

async function ListDivisions() {
  return await Request(`/conference/divisions`, {
    method: "GET",  
  })
}

async function ListTeamPlayers(sportShortName, season) {
  return await Request(`/conference/team-players/${sportShortName}/${season}`, {
    method: "GET",
  })
}

async function ListSeasons() {
  return await Request(`/conference/seasons`, {
    method: "GET",  
  })
}

async function ListSchools() {
  return await Request(`/conference/schools`, {
    method: "GET",
  })
}

async function ListSports() {
  return await Request(`/conference/sports`, {
    method: "GET",  
  })
}

async function GetPlayerSeasonStats(playerName, teamId, sport, year, conf) {
  return await Request(`/player/${playerName}/season-stats/${teamId}/${sport}/${year}/${conf}`, {
    method: "GET",
  })
}

async function GetPlayerGameStats(playerName, teamId, sport, year, startDate, endDate, conf) {
  startDate = moment(startDate).format("M/D/YYYY").replaceAll("/", "%2F")
  endDate = moment(endDate).format("M/D/YYYY").replaceAll("/", "%2F")
  return await Request(`/player/${playerName}/game-stats/${teamId}/${sport}/${year}/${startDate}/${endDate}/${conf}`, {
    method: "GET",
  })
}

export default {
  SupportCreateConference,
  SupportUpdateConference,
  SupportListConferences,
  ListDivisions,
  ListTeamPlayers,
  ListSeasons,
  ListSchools,
  ListSports,
  GetPlayerSeasonStats,
  GetPlayerGameStats,
}