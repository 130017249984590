import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
  data: () => ({
    snackbar: {
      enabled: false,
      text: "",
      color: "primary",
      timeout: 5000,
    },
  }),
  methods: {
    showSnackbar(text, color, timeout) {
      this.snackbar.enabled = true
      this.snackbar.text = text
      this.snackbar.color = color

      // Timer only resets if it's value is changed.
      this.snackbar.timeout = this.snackbar.timeout == timeout ? timeout + 1 : timeout
    },
    updateTitle(subTitle) {
      const titleElement = document.querySelector("title")
      titleElement.innerHTML = `${subTitle} - SIDEARM Voting` 
    },
  },
}).$mount('#app')
